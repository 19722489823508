<template>
  <div class="consultation-wrapper">
    <div class="content-wrapper">
      <div class="card-box">
        <scroll-list
          :page="page"
          :pageSize="pageSize"
          :total="total"
          :data="data"
          :getData="getData">
          <div class="border-bottom-1px question-box" v-for="(item, index) in data" :key="index" @click="toDetail(item)">
            <h4 :class="{'no-read': item.replyReadStat === 0 && item.replyStat}">{{item.questionContent}}</h4>
            <p>
              <span class="time">{{item.createTime}}</span>
              <span class="reply" :class="{active: item.replyStat}">{{item.replyStat ? '已回复' : '未回复'}}</span>
            </p>
          </div>
        </scroll-list>
      </div>
    </div>
    <router-link to="/consultation/add" class="edit-btn">
      <van-icon name="edit" />
    </router-link>
  </div>
</template>

<script>
import APIS from '@/api/index'
const { getConsults, changeReadStat } = APIS
export default {
  name: 'consultation',
  data () {
    return {
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10
    }
  },
  methods: {
    // 获取咨询列表
    async getData (isRefresh) {
      if (isRefresh) {
        this.page = 1
      }

      let res = await getConsults({
        start: this.page,
        limit: this.pageSize
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.totalCount
        } else {
          throw new Error(res.message)
        }
      }

      this.data = isRefresh ? res.data : this.data.concat(res.data)
      this.page++
    },
    toDetail (data) {
      changeReadStat({ consultId: data.idInteractConsult })
      this.$router.push({
        name: 'consultDetail',
        params: {
          detail: data
        }
      })
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue')
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .question-box{
    padding-bottom: 32px;
    margin-bottom: 32px;
    h4{
      font-size: 32px;
      color: #333;
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 16px;
      &.no-read::before{
        @include circle(24px);
        content: '';
        display: inline-block;
        // 在安卓手机中rem单位border-radius:50%画圆变形的解决方案
        transform: scale(.5);
        transform-origin: 0% center;
        background: #E94C4C;
      }
    }
    p{
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 22px;
      .reply{
        font-size: 24px;
        &.active{
          color: #117BEE;
        }
      }
    }
  }
  .edit-btn{
    position: fixed;
    right: 24px;
    bottom: 110px;
    @include circle(114px);
    background:#117BEE;
    font-size: 54px;
    color: #fff;
    font-weight: bold;
    box-shadow:0px 3px 11px 0px rgba(17,123,238,0.51);
  }
</style>
